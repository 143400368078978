.resume {
    display: flex;
    min-height: 100px;
    flex-wrap: wrap;
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    color: #333;
    overflow: auto;
  }
  
  .personalInfo {
    flex: 1;
    min-width: 300px;
    margin-right: 20px;
  }
  
  .rightPanel {
    max-height: 75vh;
    flex: 2;
    display: flex;
    flex-direction: column;
    min-width: 300px;
  }
  
  .card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 1.5em;

    overflow: auto;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .card h2 {
    font-size: 1.2rem;
   display: inline;
  }
  
  .card p, .card ul {
    max-height: 10%;
    font-size: 1rem
  }
  
  .card ul {
    list-style-type: circle;
  }
  
  /* 动画效果 */
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  
  /* 响应式布局 */
  @media screen and (max-width: 768px) {
    .resume {
      flex-direction: column;
    }
    .personalInfo{

        font-size: 0.8rem
      }
    .card p, .card ul {

        font-size: 0.6rem
      }
    .personalInfo {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .rightPanel {
      flex-direction: column;
    }
  }
  