
.container {
  text-align: center;
  color: #000000;
  margin-top: 10vh; /* 使用相对单位 */
}

.container > header {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  margin: 0 auto 50px auto; /* 调整 margin */

  position: relative;
  height: 45px; /* 调整高度 */
  width: 250px;
  overflow: hidden;
}

.header-headings {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.header-headings > span {
  margin: 12px 0; /* 调整 margin */
}

.header-headings.sign-in {
  transform: translateY(0);
}

.header-headings.sign-up {
  transform: translateY(-45px); /* 调整 translateY 的距离 */
}

.header-headings.forgot {
  transform: translateY(-90px); /* 调整 translateY 的距离 */
}

.options {
  display: flex;
  align-items: center;
  width: 350px;
  margin-left: -30px;
}
@media screen and (max-width: 600px) {
  .options {
    width: 100%;
  }
}
.options > li {
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s ease;
  list-style-type: none; /* 移除小点 */
}
.options > li:hover {
  opacity: 1;
}
.options > li.active {
  opacity: 1;
}
.options > li:nth-of-type(2) {
  margin-left: 15px;
}
.options > li:last-of-type {
  margin-left: auto;
  margin-right: 15px;
}

.account-form {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.account-form-fields {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.account-form-fields.sign-in {
  max-height: 123px;
}
.account-form-fields.sign-up {
  max-height: 250px;
}
.account-form-fields.forgot {
  max-height: 61px;
}
.account-form-fields > input {
  border: 0;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 1rem;
  font-family: Nunito, sans-serif;
  color: #000;
  border-radius: 5px;
}
.account-form-fields > input::-moz-placeholder {
  color: #aaa;
}
.account-form-fields > input:-ms-input-placeholder {
  color: #aaa;
}
.account-form-fields > input::placeholder {
  color: #aaa;
}
.account-form-fields > input::-webkit-input-placeholder {
  color: #aaa;
}
.account-form-fields > input:-ms-input-placeholder {
  color: #aaa;
}
.account-form-fields > input:focus {
  outline: none;
}

.btn-submit-form {
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  padding: 15px 0;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-family: Nunito, sans-serif;
  background: #6381e8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);
  transition: all 0.2s ease;
}
.btn-submit-form:hover {
  background: #4468e4;
}
.btn-submit-form:active, .btn-submit-form:focus {
  outline: none;
  background: #2e56e0;
}

.password-error{
  height: 1vh;
  font-family: Arial, Helvetica, sans-serif;
  color:#a95656;
  margin-bottom: 10px;
}

@media screen and ((max-height: 700px)){
  .container{
    margin-top: 20px;
  }
  .container > header {
    font-size: 1rem; /* 减小字体大小 */
    margin-top: -5vh;
    margin: 0 auto -5px auto;
  }

  .account-form-fields > input {
    padding: 8px;

  }

  .btn-submit-form {
    padding: 8px;
  }

  .account-form-fields.sign-in {
    max-height: 90px; /* 适合登录的高度 */
  }

  .account-form-fields.sign-up {
    max-height: 200px; /* 适合注册的高度 */
  }

  .account-form-fields.forgot {
    max-height: 45px; /* 适合忘记密码的高度 */
  }
}

